<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo" :to="{ path: '/' }">
      <!-- <vuexy-logo /> -->
      <h2 class="brand-text text-primary ml-1">
        <Logo class="pike-brand-logo" />
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1 mt-1">{{ $t("ComingSoon") }}</h2>

        <b-button
          variant="primary"
          class="mb-2 btn-sm-block"
          :to="{ path: '/' }"
        >
          {{ $t("BackToHome") }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BButton, BImg } from "bootstrap-vue";
import Logo from "@/assets/rashiCoin/logo.svg";
export default {
  components: {
    BLink,
    BButton,
    BImg,
    Logo
  },
  data() {
    return {};
  },
  computed: {}
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
